<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
});
</script>
<template>
  <div
    class="container d-flex justify-content-center align-items-center contenedor-marcas"
    style="height: 100vh">
    <div class="row g-5 justify-content-center">
      <div class="col-md-6  text-center">
        <Logos text="esformacion" tamano="large"/>
        <button @click="openLogin('esformacion')" class="btn btn-danger">
          Ingresar
        </button>
      </div>
      <div class="col-md-6 text-center">
        <Logos text="masprofesional" tamano="large"/>
        <button @click="openLogin('masprofesional')" class="btn btn-danger">
          Ingresar
        </button>
      </div>
      <!-- Logo Capacitacional
      <div class="col-4 text-center">
        <Logos text="capacitasional" tamano="large"/>
        <button @click="openLogin('capacitasional')" class="btn btn-danger">
          IngresarX
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import Logos from "../components/Logos.vue";
export default {
  name: "Home Marcas",
  data() {
    return {};
  },
  methods: {
    openLogin(marca) {
      this.$router.push({
        name: "Login",
        params: { marca: marca },
      });
    },
  },
  components:{
    Logos
  }
};
</script>
