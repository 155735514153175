import apiClient from '../axios';
export default {
    login(credenciales) {
        return apiClient.post('/loginalumno', credenciales);
    },
    recuperarContrasena(email){
        return apiClient.post('/recuperar-contrasena', email);
     },
     actualizarContrasena(id, data) {
        return apiClient.put(`/actualizar-contrasena/${id}`, data);
    },
}