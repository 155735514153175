import apiClient from '../axios';

export default {
  getClientes() {
    return apiClient.get("/clientes");
  },
  getClientesActivos() {
    return apiClient.get("/clientes/activos");
  },
  getCliente(id) {
    return apiClient.get(`/clientes/${id}`);
  },
  updateCliente(id, data) {
    return apiClient.post(`/clientes/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
