import apiClient from '../axios';

export default {
    getCertificadoporModulodeUsuario(id_modulo, id_usuario) {
        return apiClient.get(`/certificado/modulo/${id_modulo}/usuario/${id_usuario}`);
    },

    getCertificadoporCursodeUsuario(id_curso, id_usuario) {
        return apiClient.get(`/certificado/curso/${id_curso}/usuario/${id_usuario}`);
    },

    getcertificadoPorUsuario(id) {
        return apiClient.get(`/certificado/usuario/${id}`);
    },

    crearCertificado(data) {
        return apiClient.post('/certificados', data);
    },

    actualizarCertificado(id, data) {
        return apiClient.put(`/certificados/${id}`, data);
    },

    getCertificadosActivos() {
        return apiClient.get('/certificados/activos');
    },

    getVerificarCertificado(id_certificado) {
        return apiClient.get(`certificados/validacion/${id_certificado}`);
    },
};