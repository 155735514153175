import apiClient from '../axios';

export default {
    getPerfilUsuario(id) {
        return apiClient.get(`/usuarios/${id}`);
    },

    updateUsuario(id, data) {
        return apiClient.post(`/usuarios/${id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    getbuscador(id_usuario) {
        return apiClient.get(`/detalle/usuario/${id_usuario}
                `);
    },

    getbanercliente(id_cliente) {
        return apiClient.get(`/botones/cliente/${id_cliente}
                `)
    }
};