<template>
  <div class="container-fluid" style="background-color: #f7f6fe">
    <main class="main">
      <div class="container-fluid">
        <div class="row mt-4">
          <div class="col-12">
            <h4 class="text-star">Calendario</h4>
          </div>
        </div>

        <div class="row mt-2">
          <!-- Vista de Calendario (Desktop) -->
          <div class="col-12 d-none d-md-block">
            <div class="card calendar-card">
              <FullCalendar :options="calendarOptions">
                <template v-slot:eventContent="arg">
                  <div
                    class="p-2"
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    <p
                      style="
                        font-size: 11px;
                        font-weight: bold;
                        margin: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ formatTime(arg.timeText) }}<br />
                      {{ arg.event.title }}
                    </p>
                  </div>
                </template>
              </FullCalendar>
            </div>
          </div>

          <!-- Vista de Eventos (Móvil) -->
          <div class="col-12 d-block d-md-none">
            <div v-if="sortedEvents.length > 0">
              <div
                v-for="(event, index) in sortedEvents"
                :key="index"
                class="event-item mb-4"
              >
                <div class="event-header d-flex flex-column align-items-start">
                  <div class="event-title font-weight-bold">
                    {{ event.title }}
                  </div>
                  <div>
                    <span class="event-date text-muted">{{ event.date }}</span>
                    <p class="event-time font-weight-bold">
                      {{ formatTime(event.time) }}
                    </p>
                  </div>
                  <button
                    @click="openEvent(event)"
                    class="btn btn-primary btn-sm mt-2"
                  >
                    Ir a sesión
                  </button>
                </div>
                <div class="event-body mt-2">
                  <!-- Aquí se puede agregar más detalles si es necesario -->
                </div>
              </div>
            </div>
            <div v-else>
              <p>No hay actividades para mostrar.</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import esLocale from "@fullcalendar/core/locales/es";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import SesionService from "../../services/SesionService";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        weekends: true,
        events: [],
        locale: esLocale,
        eventClick: this.eventClick,
      },
      sortedEvents: [],
    };
  },
  mounted() {
    this.cargarCalendario();
  },
  components: {
    FullCalendar,
  },
  methods: {
    eventClick(args) {
      args.jsEvent.preventDefault();
      window.open(args.event.url, "_blank");
    },
    openEvent(event) {
      window.open(event.url, "_blank");
    },
    cargarCalendario() {
      this.loading = true;

      SesionService.getObtenerSesionesPorUsuario(this.user.id)
        .then((response) => {
          const arrayEvents = response.data.data;
          let arr2 = this.calendarOptions.events;
          let eventsList = [];

          arrayEvents.forEach((e) => {
            const eventDate = new Date(e.fecha + " " + e.hora_inicio);
            const currentDate = new Date();

            if (eventDate >= currentDate) {
              arr2.push({
                title: e.nombre,
                date: e.fecha + " " + e.hora_inicio,
                url: e.enlace,
              });

              eventsList.push({
                title: e.nombre,
                time: e.hora_inicio,
                date: e.fecha,
                url: e.enlace,
              });
            }
          });

          this.sortedEvents = eventsList.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );

          this.loading = false;
        })
        .catch((error) => {
          console.error("Error al obtener sesiones:", error);
          this.loading = false;
        });
    },
    formatTime(time) {
      if (typeof time !== "string") {
        console.error("Time is not a string:", time);
        return time;
      }

      const [hour, minute] = time.split(":");
      const ampm = +hour >= 12 ? "PM" : "AM";
      const hour12 = +hour % 12 || 12;
      return `${hour12}:${minute} ${ampm}`;
    },
  },
};
</script>

<style scoped>
/* Estilo para el calendario en PC */
.calendar-card {
  padding: 10px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .calendar-card {
    width: 100%; /* Ocupa el 100% del espacio disponible */
  }
}

/* Estilos para los eventos en la vista móvil */
.event-item {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  transition: all 0.3s ease;
  max-width: 100%;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.event-item:hover {
  background-color: #f1f1f1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.event-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.event-header .event-date {
  font-size: 12px;
  color: #888;
}

.event-header .event-time {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.event-body {
  margin-top: 10px;
}

.event-title {
  font-size: 16px;
  font-weight: bold;
  color: #444;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

@media (max-width: 576px) {
  .event-item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .event-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .event-header .event-date {
    font-size: 12px;
    color: #888;
  }

  .event-header .event-time {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
}
</style>
