import apiClient from '../axios';

export default {
    getModulosporCurso(id) {
        return apiClient.get(`/modulos/curso/${id}`);
    },

    getHorasModulo(id) {
        return apiClient.get(`/horas/modulo/${id}`);
    },
    getmoduloDetallado(id) {
        return apiClient.get(`/modulos/${id}`)
    }
}