import apiClient from '../axios';

export default {
    getExamenPorModulo(id_modulo) {
        return apiClient.get(`/evaluaciones/modulo/${id_modulo}`);
    },
    getPreguntasPorEvaluacion(idevaluacion) {
        return apiClient.get(`/preguntas/evaluacion/${idevaluacion}`);
    },
    guardarRespuestaAbierta(data) {
        return apiClient.post("/respuestasabiertas", data);
    },
    guardarRespuestas(data) {
        return apiClient.post('/respuestas', data);
    },
    getExamenPorCurso(id_curso) {
        return apiClient.get(`/evaluaciones/solo/curso/${id_curso}`);
    }
};