<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center w-100"
      style="height: 80vh; width: 100%"
      v-if="!haComenzado"
    >
      <div style="max-width: 400px; width: 100%">
        <h1 class="text-danger fs-6 text-center">Examen</h1>
        <p class="text-center">Curso: {{ nombre_curso }}</p>
        <h6 v-if="tipo === 'modulo'" class="text-center">
          Módulo: {{ nombre_modulo }}
        </h6>
        <div class="card p-4">
          <h5>Instrucciones</h5>
          <ul>
            <li>
              Tienes {{ cantindad_intentos }} intentos para resolver el examen
            </li>
            <li>El examen consta de {{ cantidad_preguntas }} preguntas</li>
            <li>
              Tienes {{ examen.duracion }} minutos para resolver el examen
            </li>
            <li>Las respuestas se actualizan con cada intento</li>
          </ul>
          <div
            class="btn-group mt-4 w-100"
            role="group"
            aria-label="botones examen"
          >
            <button
              type="button"
              class="btn btn-danger w-50"
              @click="comenzarExamen"
              v-show="cantindad_intentos > 0"
            >
              Empezar
            </button>
            <button
              type="button"
              class="btn btn-light w-50"
              @click="$router.go(-1)"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-center align-items-center w-100"
      style="min-height: 80vh; width: 100%"
      v-if="haComenzado && preguntaActual"
    >
      <div style="max-width: 400px; width: 100%">
        <div style="font-size: 10px" class="text-center mb-2">
          Minutos restantes : {{ cronometroExamen }}
        </div>
        <div class="card p-4">
          <p>{{ preguntaActual.pregunta }}</p>

          <div v-if="preguntaActual.tipo_pregunta === 1">
            <ul style="padding-left: 0">
              <li
                v-for="opcion in preguntaActual.opciones"
                :key="opcion.id"
                style="list-style: none; margin-bottom: 10px"
              >
                <input
                  type="radio"
                  :name="'pregunta_' + preguntaActual.id"
                  :value="opcion"
                  @change="
                    guardarRespuestas(
                      preguntaActual.tipo_pregunta,
                      preguntaActual.id,
                      preguntaActual.valor,
                      opcion.opcion,
                      opcion.condicion
                    )
                  "
                />
                {{ opcion.opcion }}
              </li>
            </ul>
          </div>
          <div v-else-if="preguntaActual.tipo_pregunta == 2">
            <label for="respuestaAbierta">Escriba su respuesta:</label>
            <input
              type="text"
              id="respuestaAbierta"
              class="form-control"
              v-model="inputRespuesta"
              @input="
                guardarRespuestas(
                  preguntaActual.tipo_pregunta,
                  preguntaActual.id,
                  preguntaActual.valor,
                  inputRespuesta,
                  0
                )
              "
              placeholder="Escriba su respuesta aquí"
            />
          </div>

          <div class="mt-4 d-flex justify-content-between">
            <button
              class="btn btn-secondary"
              v-if="preguntaIndice > 0"
              @click="anteriorPregunta"
            >
              Anterior
            </button>
            <button
              class="btn btn-danger"
              v-if="preguntaIndice < preguntas.length - 1"
              @click="siguientePregunta"
            >
              Siguiente
            </button>
            <button
              class="btn btn-success"
              v-if="preguntaIndice == preguntas.length - 1"
              @click="enviarExamen"
            >
              Enviar Examen
            </button>
          </div>
        </div>
      </div>
    </div>

    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import CursoService from "../../services/CursoService";
import ExamenService from "../../services/ExamenService";
import Preloader from "../components/Preloader.vue";
import DetalleEvaluacionService from "../../services/DetalleEvaluacionService";
import CertificadoService from "../../services/CertificadoService";

export default {
  data() {
    return {
      haComenzado: false,
      preguntaIndice: 0,
      cargando: false,
      tipo: this.$route.params.tipo,
      idtipo: this.$route.params.idmodulo || this.$route.params.idcurso,
      user: JSON.parse(localStorage.getItem("user")),
      nombre_curso: "",
      nombre_modulo: "",
      inputRespuesta: "",
      cantidad_preguntas: 0,
      examen: {
        intentos: 0,
        duracion: 0,
      },
      preguntas: [],
      respuestas: [],
      respuestasAbiertas: {},
      todasLasRespuestas: [],
      notaFinal: 0,
      modulo: null,
      detalleEvaluacionData: null,
      horasModulo: 0,
      porcentajeAprobacion: 60,
      detalle_evaluacion: {},

      cantindad_intentos: 0,
      total_valor_examen: 0,
      opcionmultiple: true,

      tiempoRestante: 0,
      intervalo: null,
      notaMaximaExamen: 0,
      notaMinimaAprobacion: 0,
    };
  },
  created() {
    this.fetchExamen();
  },
  computed: {
    preguntaActual() {
      return this.preguntas[this.preguntaIndice];
    },
    cronometroExamen() {
      const minutos = Math.floor(this.tiempoRestante / 60);
      const segundos = this.tiempoRestante % 60;
      return `${minutos}:${segundos < 10 ? "0" + segundos : segundos}`;
    },
  },
  components: {
    Preloader,
  },
  methods: {
    iniciarCronometro() {
      this.tiempoRestante = this.examen.duracion * 60;
      this.intervalo = setInterval(() => {
        if (this.tiempoRestante > 0) {
          this.tiempoRestante--;
        } else {
          clearInterval(this.intervalo);
          this.$swal({
            icon: "success",
            title: "Tiempo Terminado",
            text: "Presione OK para enviar Examen",
          }).then(() => {
            this.enviarExamen();
          });
        }
      }, 1000);
    },
    detenerCronometro() {
      clearInterval(this.intervalo);
    },

    async fetchExamen() {
      await this.fecthNombreCurso();
      try {
        this.cargando = true;
        let response;
        if (this.tipo == "modulo") {
          response = await ExamenService.getExamenPorModulo(this.idtipo);
        } else if (this.tipo == "curso") {
          response = await ExamenService.getExamenPorCurso(this.idtipo);
        }
        this.examen = response.data.data;
        this.cantindad_intentos = this.examen.intentos;
        await this.fecthPreguntas(this.examen.id);
      } catch (error) {
        console.error("Error fetching Examen:", error);
      } finally {
        this.cargando = false;
      }
    },

    async fecthNombreCurso() {
      try {
        this.cargando = true;
        let response;
        if (this.tipo === "modulo") {
          response = await CursoService.getNombreCurso(this.idtipo);
          this.nombre_curso = response.data.nombre_curso;
          this.nombre_modulo = response.data.nombre_modulo;
        } else if (this.tipo === "curso") {
          response = await CursoService.getNombreCurso(this.idtipo);
          this.nombre_curso = response.data.nombre_curso;
        }
      } catch (error) {
        console.error("Error fetching curso:", error);
      } finally {
        this.cargando = false;
      }
    },

    async fecthPreguntas(id) {
      try {
        this.cargando = true;
        const response = await ExamenService.getPreguntasPorEvaluacion(id);
        this.preguntas = response.data.data;
        this.cantidad_preguntas = this.preguntas.length;

        this.notaMaximaExamen = this.preguntas.reduce((acc, pregunta) => {
          return acc + parseInt(pregunta.valor);
        }, 0);

        console.log('nota maxima',this.notaMaximaExamen);
        

        this.notaMinimaAprobacion =
          this.notaMaximaExamen * (this.porcentajeAprobacion / 100);
        console.log('nota aprobacion',this.notaMinimaAprobacion);
        this.fetchDetalledeEvaluaciones(id, this.user.id);
      } catch (error) {
        console.error("Error fetching preguntas:", error);
      } finally {
        this.cargando = false;
      }
    },

    async fetchDetalledeEvaluaciones(examen, usuario) {
      try {
        this.cargando = true;
        const response = await DetalleEvaluacionService.detalleEvaluacion(
          examen,
          usuario
        );
        const detalleEvaluacion = response.data.data;
        if (detalleEvaluacion != 0) {
          this.detalle_evaluacion = detalleEvaluacion;
          this.cantindad_intentos =
            this.cantindad_intentos - this.detalle_evaluacion.intentos;
        } else {
          await this.crearDetalleExamen(1);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.cargando = false;
      }
    },

    async comenzarExamen() {
      try {
        this.cargando = true;
        if (this.detalle_evaluacion.intentos) {
          await this.actualizarDetalleEvaluacion(
            this.detalle_evaluacion.id,
            this.detalle_evaluacion.intentos + 1
          );
          this.haComenzado = true;
          this.iniciarCronometro();
        } else {
          await this.crearDetalleExamen(1);
          this.haComenzado = true;
          this.iniciarCronometro();
        }
      } catch (error) {
        console.error("Error al iniciar el examen:", error);
      } finally {
        this.cargando = false;
      }
    },

    async actualizarDetalleEvaluacion(id, intentos) {
      try {
        const data = {
          intentos: intentos,
          fecha_evaluacion: new Date().toISOString().slice(0, 10),
          estado: 1,
        };
        await DetalleEvaluacionService.actualizarDetalleEvaluacionIntentos(
          id,
          data
        );
      } catch (error) {
        console.error("Error al actualizar el detalle de evaluación:", error);
      }
    },

    async actualizarDetalleEvaluacionNota(nota) {
      try {
        const data = {
          id_evaluacion: this.detalle_evaluacion.id_evaluacion,
          id_alumno: this.detalle_evaluacion.id_alumno,
          nota: nota,
          fecha_evaluacion: new Date().toISOString().slice(0, 10),
          estado: 1,
        };
        await DetalleEvaluacionService.actualizarDetalleEvaluacion(
          this.detalle_evaluacion.id,
          data
        );

        if (this.opcionmultiple && nota >= this.notaMinimaAprobacion) {
          await this.generarCertificado(nota);
        } else if (this.opcionmultiple && nota < this.notaMinimaAprobacion) {
          this.$swal({
            icon: "info",
            title: "Examen No Aprobado",
            text: `Tu nota fue de ${nota}, necesitas al menos ${this.notaMinimaAprobacion.toFixed(2)} para aprobar (60% de la nota máxima).`,
          }).then(() => {
            this.$router.push("/");
          });
        }
      } catch (error) {
        console.error("Error al actualizar el detalle de evaluación:", error);
      }
    },

    async crearDetalleExamen(intentos = 1) {
      try {
        const data = {
          id_evaluacion: this.examen.id,
          id_alumno: this.user.id,
          fecha_evaluacion: new Date().toISOString().slice(0, 10),
          nota: 0,
          condicion: 0,
          intentos: intentos,
          estado: 1,
        };
        const response =
          await DetalleEvaluacionService.crearDetalleEvaluacion(data);
        this.detalle_evaluacion = response.data.data;
      } catch (error) {
        console.error("Error al crear detalle de evaluación:", error);
      } finally {
        this.cargando = false;
      }
    },

    async generarCertificado(notaFinal) {
        console.log('nota final',notaFinal);
        
      try {
        const id_usuario = this.user.id;

        let response;
        let certificados = [];

        if (this.tipo == "modulo") {
          response = await CertificadoService.getCertificadoporModulodeUsuario(
            this.idtipo,
            id_usuario
          );
        } else if (this.tipo == "curso") {
          response = await CertificadoService.getCertificadoporCursodeUsuario(
            this.idtipo,
            id_usuario
          );
        } else {
          console.error("Tipo de evaluación no válido:", this.tipo);
          return;
        }

        certificados = response.data.data || [];
        console.log('certificado',certificados);
        

        if (!certificados || typeof certificados != 'object' || Object.keys(certificados).length == 0) {
            console.log(
            "No se encontraron certificados previos. Procediendo a crear uno nuevo."
          );

          const data = {
            id_usuario: id_usuario,
            fecha_emision: new Date().toISOString().slice(0, 10),
            nota: notaFinal,
            fecha_inicio: new Date().toISOString().slice(0, 10),
            fecha_fin: new Date().toISOString().slice(0, 10),
            horas: this.horasModulo,
            codigo_certificado: `CERT-${Math.random().toString(36).substr(2, 9).toUpperCase()}`,
            certificado_lado_a: "-",
            certificado_lado_b: "-",
            estado: 1,
            ...(this.tipo === "modulo" ? { id_modulo: this.idtipo } : {}),
            ...(this.tipo === "curso" ? { id_curso: this.idtipo } : {}),
          };

          console.log("Datos para crear certificado:", data);

          try {
            await CertificadoService.crearCertificado(data);
            console.log("Certificado creado exitosamente.");
            this.$swal({
              icon: "success",
              title: "Certificado Generado",
              text: `Tu certificado ha sido generado exitosamente. Nota: ${notaFinal}`,
            }).then(() => {
              this.$router.push("/");
            });
          } catch (error) {
            console.error(
              "Error al intentar crear el certificado:",
              error.response?.data || error
            );
          }
        } else {
          const certificadoActual = certificados;

          if (notaFinal > certificadoActual.nota) {
            const data = {
              nota: notaFinal,
              fecha_emision: new Date().toISOString().slice(0, 10),
            };

            try {
              await CertificadoService.actualizarCertificado(
                certificadoActual.id,
                data
              );
              this.$swal({
                icon: "success",
                title: "Certificado Actualizado",
                text: `Tu certificado ha sido actualizado con una nueva nota de: ${notaFinal}`,
              }).then(() => {
                this.$router.push("/");
              });
            } catch (error) {
              console.error(
                "Error al intentar actualizar el certificado:",
                error.response?.data || error
              );
            }
          } else {
            this.$swal({
              icon: "info",
              title: "Examen Realizado",
              text: `Felicidades por realizar el examen. Tu nota es ${notaFinal}, no se actualizará el certificado porque la nota obtenida es igual o inferior a la registrada previamente.`,
            }).then(() => {
              this.$router.push("/");
            });
          }
        }
      } catch (error) {
        console.error(
          "Error general al generar o actualizar certificado:",
          error.response?.data || error
        );
        this.$swal({
          icon: "error",
          title: "Error",
          text: "No se pudo generar el certificado. Por favor, intente nuevamente.",
        });
      }
    },
    siguientePregunta() {
      if (this.preguntaIndice < this.preguntas.length - 1) {
        this.preguntaIndice++;
      }
    },

    anteriorPregunta() {
      if (this.preguntaIndice > 0) {
        this.preguntaIndice--;
      }
    },

    guardarRespuestas(tipo, idpregunta, valor, respuesta, es_verdadero) {
      if (tipo == 2) {
        this.opcionmultiple = false;
      }

      if (es_verdadero == 1) {
        this.total_valor_examen =
          parseInt(this.total_valor_examen) + parseInt(valor);
      }
      let respuestasObjeto = {
        id_pregunta: idpregunta,
        id_detalle_evaluacion: this.detalle_evaluacion.id,
        respuesta: respuesta,
        es_verdadero: es_verdadero,
        estado: 1,
      };
      const index = this.respuestas.findIndex(
        (res) => res.id_pregunta === idpregunta
      );
      if (index !== -1) {
        this.respuestas[index] = respuestasObjeto;
      } else {
        this.respuestas.push(respuestasObjeto);
      }
    },

    async enviarExamen() {
      this.detenerCronometro();

      try {
        this.cargando = true;
        await ExamenService.guardarRespuestas(this.respuestas);
        /*
        for (const respuesta of this.respuestas) {
          await ExamenService.guardarRespuestas(respuesta);
        }*/

        if (!this.opcionmultiple) {
          this.$swal({
            icon: "success",
            title: "Examen Enviado",
            text: "Tus respuestas han sido enviadas con éxito.",
          }).then(() => {
            this.$router.push("/");
          });
        } else {
          await this.actualizarDetalleEvaluacionNota(this.total_valor_examen);
        }
      } catch (error) {
        console.error("Error enviando Examen:", error);
      } finally {
        this.cargando = false;
      }
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  .card {
    padding: 1rem;
  }

  h1,
  h5,
  p,
  label {
    font-size: 14px;
  }

  button {
    font-size: 14px;
  }

  ul {
    margin-bottom: 0.5rem;
  }
}
</style>
