import apiClient from '../axios';

export default {
    getLeccion(id) {
        return apiClient.get(`/lecciones/${id}`);
    },
    getLeccionesPorModulo(id_modulo) {
        return apiClient.get(`/lecciones/modulo/${id_modulo}`)
    },
    getLeccionDetallada(id_leccion) {
        return apiClient.get(`/lecciones/detallado/${id_leccion}`)
    }
};