<template>
  <div class="certificates-container">
    <h2 class="mb-4">Mis Certificados</h2>
    
    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col" class="text-primary">Nombres Curso <i class="bi bi-question-circle-fill ms-1"></i></th>
            <th scope="col" class="text-primary text-center">Certificado del Modulo <i class="bi bi-question-circle-fill ms-1"></i></th>
            <th scope="col" class="text-primary text-center">Certificado del Curso <i class="bi bi-question-circle-fill ms-1"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(c, index) in cursos_matriculados" :key="c.id">
            <td>
              <div class="d-flex align-items-center">
                <div class="certificate-icon me-3" :class="{ 'bg-primary': index === 1, 'bg-warning': index !== 1 }"></div>
                <p>{{ c.curso.nombre }}</p>
              </div>
            </td>
            <td class="text-center">
              <button @click="irModulos(c.curso.id)" class="btn btn-primary">Certificados por Módulo</button>
            </td>
            <td class="text-center">
              <template v-if="c.certificadoDisponible">
                <button @click="descargarCertificadoCurso(c.curso.id)" class="btn btn-download">
                  <i class="bi bi-download"></i> Descargar
                </button>
              </template>
              <template v-else>
                <span>Sin certificado</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import MatriculaService from "@/services/MatriculaService";
import CertificadoService from "@/services/CertificadoService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "CertificadosComponente",
  components: {
    Preloader,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      cursos_matriculados: [],
      cargando: false,
    };
  },
  created() {
    if (this.user) {
      this.getCursosMatriculadosPorUsuario(this.user.id);
    }
  },
  methods: {
    async getCursosMatriculadosPorUsuario(id) {
      try {
        this.cargando = true;
        const responseMatriculas = await MatriculaService.getCursosMatriculadosPorUsuario(id);
        const cursos = responseMatriculas.data.data;

        // Verificar existencia de certificados
        const cursosConCertificados = await Promise.all(
          cursos.map(async (curso) => {
            try {
              const responseCertificado = await CertificadoService.getCertificadoporCursodeUsuario(
                curso.curso.id,
                this.user.id
              );
              const certificado = responseCertificado.data.data;
              return {
                ...curso,
                certificadoDisponible: !!certificado, 
              };
            } catch {
              return {
                ...curso,
                certificadoDisponible: false,
              };
            }
          })
        );

        this.cursos_matriculados = cursosConCertificados;
      } catch (error) {
        console.error("Error al obtener cursos matriculados:", error);
      } finally {
        this.cargando = false;
      }
    },

    async descargarCertificadoCurso(id_curso) {
      try {
        const response = await CertificadoService.getCertificadoporCursodeUsuario(id_curso, this.user.id);
        const certificado = response.data.data;

        if (certificado && certificado.id) {
          const url = `https://certificado.micampusenlinea.com/certificadoacc.php?id=${certificado.id}`;
          window.open(url, "_blank");
        } else {
          this.$swal({
            icon: "warning",
            title: "Certificado no disponible",
            text: "Este curso no tiene un certificado disponible para descargar.",
          });
        }
      } catch (error) {
        console.error("Error al intentar descargar el certificado:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al intentar descargar el certificado.",
        });
      }
    },

    irModulos(id_curso) {
      this.$router.push({
        name: "CertificadoporModulo",
        params: {
          id_curso: id_curso,
        },
      });
    },
  },
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.0/font/bootstrap-icons.css");

.certificates-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.table th {
  font-weight: normal;
  font-size: 0.9rem;
  padding-top: 0;
  padding-bottom: 15px;
}

.table td {
  padding-top: 12px;
  padding-bottom: 12px;
  vertical-align: middle;
}

.certificate-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.btn-download {
  padding: 6px 10px;
  border-radius: 6px;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-primary {
  background-color: #ff9800 !important;
}

.text-primary {
  color: #3B348C !important;
}

.bi-question-circle-fill {
  font-size: 0.8rem;
}

.bi-download {
  font-size: 1.2rem;
}
</style>
