<template>
  <div v-if="logo">
    <img
      :src="logo.url"
      :alt="'Logo de ' + logo.nombre"
      :class="imageSize"
    />
  </div>
  <p v-else>Cargando logo...</p>
</template>

<script>
import ClienteService from "@/services/ClienteService";

export default {
  name: "Logos",
  props: {
    tamano: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      logo: null,
    };
  },
  computed: {
    imageSize() {
      return {
        "img-small": this.tamano === "small",
        "img-medium": this.tamano === "medium",
        "img-large": this.tamano === "large",
      };
    },
  },
  methods: {
    async fetchLogo() {
      try {
        const response = await ClienteService.getClientesActivos();

        const cliente = response.data.data.find((cliente) => {
          if (this.text === "esformacion") {
            return cliente.id === 1; 
          } else if (this.text === "masprofesional") {
            return cliente.id === 2; 
          }else if (this.text === "capacitacional") {
            return cliente.id === 3; 
          }
          return false;
        });

        if (cliente) {
          this.logo = {
            id: cliente.id,
            url: `${process.env.VUE_APP_API_URL}/storage/${cliente.logo}`,
            nombre: cliente.nombre || "Cliente",
          };
        }
      } catch (error) {
        console.error("Error al obtener el logo:", error);
        this.logo = null;
      }
    },
  },
  mounted() {
    this.fetchLogo();
  },
};
</script>

<style scoped>
.img-small {
  width: 100px;
}
.img-medium {
  width: 40%;
}
.img-large {
  width: 100%;
}
</style>
