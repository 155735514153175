<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
});
</script>

<template>
  <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
    <div class="row">
      <div class="col-md-6 imagen-pc">
        <img
          src="@/assets/images/fondos/joven-en-laptop.webp"
          class="img-fluid"
          alt="Joven en Laptop"
        />
      </div>
      <div class="col-md-6">
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-header text-center">
             
              <h3>Recuperar Contraseña</h3>
              <Preloader :visible="cargando" />
            </div>
            <div class="card-body">
              <form @submit.prevent="recuperarContrasena">
                <div class="mb-3">
                  <p>Introduce nueva contraseña</p>                 
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="password" class="form-label">Contraseña</label>
                        <input type="password" class="form-control" id="password" v-model="form.password" />
                         </div>
                    <div class="col-12 mb-3">
                        <label for="password_confirmation" class="form-label">Repetir Contraseña</label>
                        <input type="password" class="form-control" id="password_confirmation" v-model="form.password_confirmation" />
                     </div> 
                </div>
                <button type="submit" class="btn btn-primary w-100">
                  Restablecer Contraseña
                </button>     
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "Login",
  data() {
    return {
      idusuario: this.$route.params.idusuario,
      form: {
        password: '',
        password_confirmation:''
      },
      cargando: false,
      vistaRecuperacion: false,
    };
  },
  methods: {
     async recuperarContrasena(){
        this.cargando=true;

        try {
            const response = await AuthService.actualizarContrasena(this.idusuario,this.form);
            console.log(response);
                if (response.data.status === 'success') {
                    this.$swal({
                        icon: "success",
                        title: "Contraseña Restablecida",
                        text: response.data.message,
                      }).then(() => {
                    this.$router.push('/plataformas');
                    });
                } else {
                 this.$swal({
                    icon: "error",
                    title: "Error",
                    text: 'Las contraseñas deben ser las mismas y deben tener como mínimo 8 caracteres',
                });
            }           
        } catch (error) {
            this.$swal({
        icon: "error",
        title: "Error",
        text: 'Las contraseñas deben ser las mismas y deben tener como mínimo 8 caracteres',
    });
            
        }finally{
            this.cargando=false
        }
    }
   
    
  },
  components: {
    Preloader,
  },
};
</script>

<style scoped>
@media (min-width: 320px) and (max-width: 480px) {
  .imagen-pc {
    display: none;
  }

  .col-md-6 {
    padding: 0; 
  }

  .row {
    margin: 5px; 
    padding: 5px;
  }

  .card {
    width: 100%; 
    margin: 0; 
    border-radius: 15px; 
  }
}
</style>
