<template>
  <div class="contact-container" v-if="clienteInfo">
    <h2 class="contact-title">{{ clienteInfo.nombre }}</h2>
    <div class="contact-list">
      <!-- Facebook -->
      <div class="contact-item">
        <div class="icon-container facebook">
          <i class="bi bi-facebook"></i>
        </div>
        <div class="contact-info">
          <p class="contact-type">Facebook</p>
          <p class="contact-detail">
            <a :href="clienteInfo.facebook" target="_blank">{{ clienteInfo.facebook }}</a>
          </p>
        </div>
      </div>

      <!-- Instagram -->
      <div class="contact-item">
        <div class="icon-container instagram">
          <i class="bi bi-instagram"></i>
        </div>
        <div class="contact-info">
          <p class="contact-type">Instagram</p>
          <p class="contact-detail">
            <a :href="clienteInfo.instagram" target="_blank">{{ clienteInfo.instagram }}</a>
          </p>
        </div>
      </div>

      <!-- Correo -->
      <div class="contact-item" @click="redirectToGmail(clienteInfo.correo)">
        <div class="icon-container email">
          <i class="bi bi-envelope-fill"></i>
        </div>
        <div class="contact-info">
          <p class="contact-type">Correo electrónico</p>
          <p class="contact-detail">{{ clienteInfo.correo }}</p>
        </div>
      </div>

      <!-- WhatsApp -->
      <div class="contact-item" @click="redirectTo('https://wa.me/' + clienteInfo.whatsapp.replace(/[^0-9]/g, ''))">
        <div class="icon-container whatsapp">
          <i class="bi bi-whatsapp"></i>
        </div>
        <div class="contact-info">
          <p class="contact-type">WhatsApp</p>
          <p class="contact-detail">{{ clienteInfo.whatsapp }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      id_cliente: null,
      clienteInfo: null,
    };
  },
  methods: {
    async obtenerClienteId() {
      try {
        if (this.user && this.user.id_cliente) {
          this.id_cliente = this.user.id_cliente;
          this.setClienteInfo(this.id_cliente);
        } else {
          console.error("No se pudo obtener el ID del cliente del usuario.");
        }
      } catch (error) {
        console.error("Error al obtener el ID del cliente:", error);
      }
    },
    setClienteInfo(id) {
      const clienteData = {
        1: {
          nombre: "EsFormación",
          facebook: "https://www.facebook.com/esformacionpe",
          instagram: "https://www.instagram.com/esformacionperu/",
          correo: "info@esformacion.pe",
          whatsapp: "+51 955 587 754",
        },
        2: {
          nombre: "MásProfesional",
          facebook: "https://www.facebook.com/masprofesionalperu",
          instagram: "https://www.instagram.com/masprofesional/",
          correo: "esmasprofesional@gmail.com",
          whatsapp: "+51 923 850 662",
        },
        3: {
          nombre: "Capacitacional",
          facebook: "https://www.facebook.com/capacitacional",
          instagram: "https://www.instagram.com/capacitacional/",
          correo: "info@capacitacional.pe",
          whatsapp: "+51 923 850 663",
        },
      };

      this.clienteInfo = clienteData[id] || null;
    },
    redirectTo(url) {
      window.open(url, "_blank"); // Abre en una nueva pestaña
    },
    redirectToGmail(email) {
      const gmailUrl = `https://mail.google.com/mail/?view=cm&to=${email}`;
      window.open(gmailUrl, "_blank"); // Abre Gmail en una nueva pestaña con el destinatario
    },
  },
  mounted() {
    this.obtenerClienteId();
  },
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.0/font/bootstrap-icons.css");

.contact-container {
  max-width: 400px;
  padding: 20px;
}

.contact-title {
  color: #3b348c;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.contact-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-item {
  display: flex;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 15px;
}

.contact-info {
  display: flex;
  flex-direction: column;
}

.contact-type {
  font-weight: bold;
  margin: 0;
}

.contact-detail {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}

.facebook {
  color: #1877f2;
}

.instagram {
  color: #e4405f;
}

.email {
  color: #3b348c;
}

.whatsapp {
  color: #25d366;
}

@media (max-width: 768px) {
  .contact-container {
    max-width: 100%;
    padding: 15px;
  }

  .contact-title {
    font-size: 1.4rem;
  }

  .contact-item {
    flex-direction: column;
    align-items: center;
    padding: 12px;
  }

  .icon-container {
    margin-bottom: 10px;
    font-size: 2rem;
  }

  .contact-info {
    align-items: center;
    text-align: center;
  }

  .contact-type {
    font-size: 1rem;
  }

  .contact-detail {
    font-size: 1rem;
    white-space: normal;
    word-wrap: break-word;
  }
}
</style>
