import apiClient from '../axios';

export default {
    getNotificacionesPorUsuario(id_usuario) {
        return apiClient.get(`/notificaciones/usuario/${id_usuario}`);
    },

    desactivar(id_notificacion) {
        return apiClient.put(`/notificaciones/desactivar/${id_notificacion}`);
    },

    getbuscador(id_usuario) {
        return apiClient.get(`/detalle/usuario/${id_usuario}`);
    },
};