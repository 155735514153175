<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

const store = useStore();

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
});
</script>

<template>
  <div class="container-fluid py-4 d-flex align-items-center justify-content-center no-background">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">
          <div class="card shadow-lg border-0 rounded-4 overflow-hidden">
            <div class="card-body p-4 p-md-5">
              <div class="row g-4 align-items-start align-items-md-center flex-column flex-md-row">
                
                <!-- Columna Izquierda: Perfil -->
                <div class="col-12 col-md-4 text-center mb-4 mb-md-0">
                  <div class="mb-3">
                    <div class="mx-auto rounded-circle overflow-hidden shadow-sm profile-image-container">
                      <img 
                        :src="certificado.imagen || 'https://via.placeholder.com/150'" 
                        alt="Profile Picture" 
                        class="img-fluid w-100 h-100 object-fit-cover"
                        @error="certificado.imagen = 'https://via.placeholder.com/150'"
                      />
                    </div>
                  </div>
                  <h5 class="fw-bold mb-2">{{ certificado.alumno }}</h5>
                  <p class="text-muted mb-3">{{ certificado.profesion }}</p>
                  <div class="d-grid mb-3">
                    <button class="btn btn-success btn-sm">
                      <i class="bi bi-check-circle me-2"></i> Verificado
                    </button>
                  </div>
                  <div class="d-flex justify-content-center gap-3 social-icons">
                    <a :href="whatsappShareUrl" target="_blank" class="btn btn-outline-success btn-circle" aria-label="Compartir en WhatsApp">
                      <i class="bi bi-whatsapp"></i>
                    </a>
                    <a :href="facebookShareUrl" target="_blank" class="btn btn-outline-primary btn-circle" aria-label="Compartir en Facebook">
                      <i class="bi bi-facebook"></i>
                    </a>
                    <a :href="linkedinShareUrl" target="_blank" class="btn btn-outline-info btn-circle" aria-label="Compartir en LinkedIn">
                      <i class="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                
                <!-- Columna Derecha: Detalles del Certificado -->
                <div class="col-12 col-md-8">
                  <div class="card border-0 bg-soft-primary h-100">
                    <div class="card-body p-3 p-md-4">
                      <h4 class="card-title text-primary mb-4 d-flex align-items-center">
                        <i class="bi bi-award me-2"></i>
                        Detalles del Certificado
                      </h4>
                      <div class="row g-3">
                        
                        <div class="col-12">
                          <div class="d-flex justify-content-between mb-2 flex-wrap">
                            <span class="fw-bold text-muted">
                              {{ certificado.modulo ? 'Nombre Módulo' : 'Nombre Curso' }}
                            </span>
                            <span class="text-truncate">
                              {{ certificado.modulo || certificado.curso }}
                            </span>
                          </div>
                          <hr class="my-2">
                        </div>
                        
                        <div class="col-12">
                          <div class="d-flex justify-content-between mb-2 flex-wrap">
                            <span class="fw-bold text-muted">Fecha Inicio</span>
                            <span>{{ getFechaInicio }}</span>
                          </div>
                          <hr class="my-2">
                        </div>
                        
                        <div class="col-12">
                          <div class="d-flex justify-content-between mb-2 flex-wrap">
                            <span class="fw-bold text-muted">Fecha Finalización</span>
                            <span>{{ getFechaFin }}</span>
                          </div>
                          <hr class="my-2">
                        </div>
                        
                        <div class="col-12">
                          <div class="d-flex justify-content-between mb-2 flex-wrap">
                            <span class="fw-bold text-muted">Horas</span>
                            <span>{{ certificado.horas || "No disponible" }}</span>
                          </div>
                          <hr class="my-2">
                        </div>
                        
                        <div class="col-12">
                          <div class="d-flex justify-content-between mb-2 flex-wrap">
                            <span class="fw-bold text-muted">Nota</span>
                            <span>{{ certificado.nota || "No disponible" }}</span>
                          </div>
                          <hr class="my-2">
                        </div>
                        
                        <div class="col-12">
                          <div class="d-flex justify-content-between flex-wrap">
                            <span class="fw-bold text-muted">Código Certificado</span>
                            <span>{{ certificado.codigo_certificado || "No disponible" }}</span>
                          </div>
                        </div>
                      
                      </div> 
                    </div>
                  </div>
                </div>
                
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CertificadoService from "../../services/CertificadoService";

const generateLinkedInCertificateURL = (name, issuingOrganization, issueDate, credentialURL) => {
    const baseURL = "https://www.linkedin.com/profile/add?";
    const params = new URLSearchParams({
        startTask: "CERTIFICATION_NAME",
        name,
        organizationName: issuingOrganization,
        issueYear: issueDate.getFullYear(),
        issueMonth: issueDate.getMonth() + 1,
        certificationUrl: credentialURL
    });

    return baseURL + params.toString();
};

export default {
  name: "ProfileForm",
  data() {
    return {
      certificado: {
        id: null,
        id_modulo: null,
        id_curso: null,
        id_usuario: "", 
        fecha_emision: "",
        nota: "",
        fecha_inicio: "",
        fecha_fin: "",
        horas: "",
        estado: null,
        codigo_certificado: "",
        certificado_lado_a: null,
        certificado_lado_b: null,
        alumno: "",
        profesion: "",
        imagen: "",
        curso: "",
        CursofechaInicio: "",
        CursofechaFin: "",
        fechaInicio: null,
        fechaFin: null,
        modulo: null,
        organizacion: "",
        nombre_cliente: "", 
      },
      id_certificado: null,
      organizationName: "", 
    };
  },

  computed: {
    shareUrl() {
      return window.location.href;
    },
    whatsappShareUrl() {
      const text = `Mira mi certificado: ${this.shareUrl}`;
      return `https://wa.me/?text=${encodeURIComponent(text)}`;
    },
    facebookShareUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.shareUrl
      )}`;
    },
    linkedinShareUrl() {
      const name = this.certificado.modulo || this.certificado.curso || "Certificación";
      const issuingOrganization = this.organizationName;
      const issueDate = this.certificado.fecha_emision ? new Date(this.certificado.fecha_emision) : new Date();
      const credentialURL = this.certificado.codigo_certificado
        ? `https://www.miweb.com/certificado/${this.certificado.codigo_certificado}`
        : "https://www.miweb.com/certificado";

      return generateLinkedInCertificateURL(name, issuingOrganization, issueDate, credentialURL);
    },
    getFechaInicio() {
      return this.certificado.modulo
        ? this.formatDate(this.certificado.fechaInicio)
        : this.formatDate(this.certificado.CursofechaInicio);
    },
    getFechaFin() {
      return this.certificado.modulo
        ? this.formatDate(this.certificado.fechaFin)
        : this.formatDate(this.certificado.CursofechaFin);
    },
  },

  methods: {
    
    async obtenerCertificado() {
      try {
        const response = await CertificadoService.getVerificarCertificado(
          this.id_certificado
        );
        this.certificado = response.data.data;

        if (this.certificado.imagen) {
          this.certificado.imagen =
            process.env.VUE_APP_API_URL +
            "/storage/" +
            this.certificado.imagen;
        } else {
          this.certificado.imagen = "https://via.placeholder.com/150";
        }

        if (this.certificado.nombre_cliente) {
          this.organizationName = this.certificado.nombre_cliente;
        } else {
          this.organizationName = "Tu Organización";
        }
      } catch (error) {
        this.organizationName = "Tu Organización";
      }
    },

    formatDate(dateString) {
      if (!dateString) return "No disponible";

      const date = new Date(dateString + "T00:00:00"); 
      return date.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },

  created() {
    this.id_certificado = this.$route.params.id_certificado;
    this.obtenerCertificado();
  },
};
</script>

<style scoped>
.no-background {
  background: none !important;
  min-height: 100vh;
  padding: 1rem;
}

.bg-soft-primary {
  background-color: rgba(0, 123, 255, 0.05);
}

.profile-image-container {
  width: 150px;
  height: 150px;
  border: 4px solid #fff;
  margin: 0 auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: #fff;
}

.btn-circle {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.btn-circle:hover {
  transform: scale(1.1);
  background-color: rgba(0,0,0,0.05);
}

.social-icons a {
  text-decoration: none;

}

@media (max-width: 576px) {
  .profile-image-container {
    width: 100px;
    height: 100px;
  }
  
  .btn-circle {
    width: 40px;
    height: 40px;
  }

  h5.fw-bold {
    font-size: 1.1rem;
  }

  p.text-muted {
    font-size: 0.9rem;
  }

  .social-icons {
    gap: 0.5rem;
  }

  .card-title {
    font-size: 1rem;
  }

  .card .card-body .fw-bold {
    font-size: 0.9rem;
  }

  .card .card-body span {
    font-size: 0.85rem;
  }
}

</style>
