<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
});
</script>

<template>
  <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
    <div class="row">
      <div class="col-md-6 imagen-pc">
        <img
          src="@/assets/images/fondos/joven-en-laptop.webp"
          class="img-fluid"
          alt="Joven en Laptop"
        />
      </div>
      <div class="col-md-6">
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-header text-center">
              <Logos :text="marca" tamano="medium" />
              <h3>{{ vistaRecuperacion ? 'Recuperar Contraseña' : 'Iniciar Sesión' }}</h3>
            </div>
            <div class="card-body">
              <form v-if="!vistaRecuperacion" @submit.prevent="handleLogin">
                <div class="mb-3">
                  <label for="email" class="form-label">Correo Electrónico</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="form.email"
                    required
                    placeholder="Ingrese su correo"
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Contraseña</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    v-model="form.password"
                    required
                    placeholder="Ingrese su contraseña"
                  />
                </div>

                <button type="submit" class="btn btn-primary w-100">
                  Iniciar Sesión
                </button>
                <Preloader :visible="cargando" />
              </form>

              <form v-else @submit.prevent="recuperarContrasena">
                <div class="mb-3">
                  <p>Introduce tu correo electrónico para buscar tu cuenta.</p>
                  <input
                    type="email"
                    class="form-control"
                    id="emailRecuperacion"
                    v-model="emailRecuperacion"
                    required
                    placeholder="Ingrese su correo para recuperar la contraseña"
                  />
                </div>
                <button
                  type="submit"
                  class="btn btn-primary w-100"
                  :disabled="cargando"
                >
                  {{ cargando ? 'Cargando...' : 'Buscar' }}
                </button>             
                <div class="mt-3">
                  <p class="text-muted">
                    Si no recibiste el correo electrónico, comunícate con el administrador:
                  </p>
                  <p>Correo: esformacion@gmail.com<br>
                  Teléfono: 987 456 321</p>
                </div>
              </form>

              <a @click="toggleVistaRecuperacion" class="text-center mt-3" style="cursor:pointer;">
                {{ vistaRecuperacion ? 'Volver a Iniciar Sesión' : '¿Has olvidado la contraseña?' }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logos from "../components/Logos.vue";
import AuthService from "../../services/AuthService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "Login",
  data() {
    return {
      marca: this.$route.params.marca,
      form: {
        email: "",
        password: "",
      },
      emailRecuperacion: "",
      cargando: false,
      vistaRecuperacion: false,
      
    };
  },
  methods: {
    async handleLogin() {
      this.cargando = true;
      try {
       
        const response = await AuthService.login(this.form);
        const user = response.data.user;
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("user", JSON.stringify(user));
        this.$store.commit("setUser", user);

        this.$router.push({
          name: "Inicio",
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        this.cargando = false;
      }
    },
    async recuperarContrasena() {
      this.cargando = true;
      let form={
        'email':this.emailRecuperacion
      }
  
      // recuperar la contraseña
      try {
        const response= await AuthService.recuperarContrasena(form);
      
        if (response.data.status === 'success') {
        this.$swal({
            icon: "success",
            title: "Correo Enviado",
            text: response.data.message,
        }).then(() => {
        this.$router.push('/plataformas'); // Redirigir a la página de inicio de sesión
      });
    } else {
        // En caso de error explícito devuelto por el backend
        this.$swal({
            icon: "error",
            title: "Error",
            text: response.data.message,
        });
    }
        
      } catch (error) {
    // Manejar errores de la API o del servidor
    this.$swal({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Ocurrió un error inesperado",
    });
} finally {
    this.cargando = false;
}
     
    },
    toggleVistaRecuperacion() {
      this.vistaRecuperacion = !this.vistaRecuperacion; 
    },
  },
  components: {
    Logos,
    Preloader,
  },
};
</script>

<style scoped>
@media (min-width: 320px) and (max-width: 480px) {
  .imagen-pc {
    display: none;
  }

  .col-md-6 {
    padding: 0; 
  }

  .row {
    margin: 5px; 
    padding: 5px;
  }

  .card {
    width: 100%; 
    margin: 0; 
    border-radius: 15px; 
  }
}
</style>
