<template>
  <div class="certificates-container">
    <h2 class="mb-4">Mis Certificados</h2>

    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col">Lista de Certificados</th>
            <th scope="col" class="text-center">Calificación</th>
            <th scope="col" class="text-center">Descargar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(modulo, index) in modulos" :key="modulo.id">
            <td>
              <div class="d-flex align-items-center">
                <div
                  class="certificate-icon me-3"
                  :class="getCertificateIconClass(index)"
                ></div>
                <p class="mb-0">{{ modulo.nombre }}</p>
              </div>
            </td>
            <td class="text-center">
              {{ obtenerCalificacion(modulo.id) }}
            </td>
            <td class="text-center">
              <span
                v-if="
                  certificados[modulo.id] &&
                  certificados[modulo.id].codigo_certificado
                "
              >
                <button
                  @click="descargarCertificado(modulo.id)"
                  class="btn btn-download"
                >
                  <i class="bi bi-download"></i>
                </button>
              </span>
              <span v-else>Sin Certificado</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import CalificacionService from "../../services/CalificacionService";
import CertificadoService from "../../services/CertificadoService";
import ModuloService from "../../services/ModuloService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "CertificadosModulos",
  components: {
    Preloader,
  },
  data() {
    return {
      modulos: [],
      id_curso: this.$route.params.id_curso,
      calificaciones: {},
      certificados: {},
      cargando: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    this.fetchModulos(this.id_curso);
  },
  methods: {
    async fetchModulos(id_curso) {
      try {
        this.cargando = true;
        const response = await ModuloService.getModulosporCurso(id_curso);
        this.modulos = response.data.data;
        for (const modulo of this.modulos) {
          await this.obtenerCalificacionYCertificado(modulo.id);
        }
      } catch (error) {
        console.error("Error al cargar los módulos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los módulos.",
        });
      } finally {
        this.cargando = false;
      }
    },

    async obtenerCalificacionYCertificado(id_modulo) {
      await Promise.all([
        this.llamarCalificacion(id_modulo),
        this.llamarCertificado(id_modulo),
      ]);
    },

    async llamarCalificacion(id_modulo) {
      try {
        const response =
          await CalificacionService.getCalificacionporModulodeUsuario(
            id_modulo,
            this.user.id
          );
        this.calificaciones[id_modulo] =
          response.data.data[0]?.calificacion || "Sin calificación";
      } catch {
        ;
      }
    },

    async llamarCertificado(id_modulo) {
      try {
        const response =
          await CertificadoService.getCertificadoporModulodeUsuario(
            id_modulo,
            this.user.id
          );
        this.certificados[id_modulo] = response.data.data[0] || null;
      } catch (error) {
        console.error("Error al obtener el certificado:", error);
      }
    },

    obtenerCalificacion(id_modulo) {
      if (this.certificados[id_modulo] && this.certificados[id_modulo].nota) {
        return this.certificados[id_modulo].nota;
      }
      return this.calificaciones[id_modulo] || "Sin calificación";
    },

    descargarCertificado(id_modulo) {
      const certificado = this.certificados[id_modulo];

      if (certificado) {
        const url = `https://certificado.micampusenlinea.com/certificadoacc.php?id=${certificado.id}`;
        window.open(url, "_blank");
      } else {
        console.warn("Certificado no disponible para descargar.");
        this.$swal({
          icon: "error",
          title: "Certificado no disponible",
          text: "Este módulo no tiene un certificado disponible para descargar.",
        });
      }
    },

    getCertificateIconClass(index) {
      return index % 2 === 0 ? "bg-primary" : "bg-warning";
    },
  },
};
</script>

<style scoped>
.certificates-container {
  padding: 20px;
  background-color: #f8f9fa;
}

.table th {
  font-weight: bold;
  color: #6c757d;
}

.certificate-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.btn-download {
  padding: 6px 10px;
  border-radius: 6px;
}

.bg-primary {
  background-color: #ff9800 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.btn-info {
  background-color: #17a2b8;
  color: white;
}

.btn-light {
  background-color: white;
  color: #3b348c;
}
</style>
