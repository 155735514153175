import apiClient from '../axios';

export default {
    detalleEvaluacion(examen_id, user_id) {
        return apiClient.get(`/detalle/evaluacion/${examen_id}/alumno/${user_id}`);
    },

    actualizarDetalleEvaluacionIntentos(examen_id) {
        return apiClient.get(`/detalle-evaluacion/${examen_id}/incrementar-intento`);
    },
    actualizarDetalleEvaluacion(id,data) {
        return apiClient.put(`/detalle-evaluacion/${id}`, data);
    },

    crearDetalleEvaluacion(data) {
        return apiClient.post('/detalle-evaluacion', data);
    },
}